import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import { clearError } from '../../actions/errorActions';

import '../../../stylesheets/boeing.postcss';

// Components
import { BoeingHome, BoeingSchedule, BoeingLocation, BoeingError } from './BoeingLayout.imports-loadable';

class BoeingLayout extends Component {
  componentDidUpdate(prevProps, prevState) {
    const { errors } = this.props;
    const { pathname: currentPath } = this.props.location;
    const { pathname: prevPath } = prevProps.location;

    if (prevPath !== currentPath) {
      if (errors.status) {
        this.props.clearError();
      }
    }
  }

  render() {
    const { errors } = this.props;

    let showPage = true;
    if (errors.status && !errors.modal) {
      showPage = false;
    }

    return (
      <div className='BoeingContent'>
        {(errors.status === 500 && !errors.modal) &&
          <BoeingError />
        }

        {(errors.status === 404 && !errors.modal) &&
          <BoeingError />
        }

        {showPage &&
          <Switch>
            {/* <Route
              path="/schedule/:id"
              component={BoeingLocation}
            /> */}
            {/* <Route
              path="/order"
              component={BoeingHome}
            /> */}
            <Route
              path="/"
              component={BoeingSchedule}
            />
          </Switch>
        }

        <div className='Support'>
          <p>
            <span className='bold'>Questions?</span> <a href={"mailto:support@seattlefoodtruck.com"}>Support@SeattleFoodTruck.com</a>
          </p>
        </div>

      </div>
    );
  }
}

BoeingLayout.propTypes = {
  children: PropTypes.object,
  clearError: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
};

function mapStateToProps(state, props) {
  return {
    errors: state.errors
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearError() {
      dispatch(clearError());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BoeingLayout);